<template>
  <BCard>
    <AppCollapse>
      <AppCollapseItem
        v-for="curation in state.content"
        :key="curation.uuid"
        :title="getTitle(curation)"
      >
        <BRow>
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="NAME"
            >
              <BFormInput
                v-model="curation.name"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="STATE"
            >
              <TagbySelect
                v-model="curation.state"
                :options="stateOptions"
              />
            </BFormGroup>
          </BCol>

        </BRow>

        <hr>

        <h5>필터</h5>

        <BRow>
          <BCol cols="12">
            <BFormGroup label="CAMPAIGN_IDX_LIST">
              <BFormTextarea
                v-model="curation.idx_list_text"
                rows="5"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="4"
            md="6"
          >
            <BFormGroup label="모집중">
              <BFormCheckbox
                v-model="curation.filter.is_recruit"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="4"
            md="6"
          >
            <BFormGroup label="리워드">
              <TagbyRangeInputV2
                v-model="curation.filter.reward__price"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="4"
            md="6"
          >
            <BFormGroup label="신청자">
              <TagbyRangeInputV2
                v-model="curation.filter.apply_count"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="4"
            md="6"
          >
            <BFormGroup label="조회날짜로부터 몇 일 후 선정">
              <BFormInput
                v-model="curation.filter.selection_at__dday"
                number
                type="number"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="4"
            md="6"
          >
            <BFormGroup label="조회날짜로부터 몇 일 이내 등록">
              <BFormInput
                v-model="curation.filter.reversed_registered_at__dday"
                number
                type="number"
              />
            </BFormGroup>
          </BCol>
        </BRow>

        <BRow class="d-flex justify-content-end">
          <BButton
            class="mr-1"
            variant="outline-danger"
            @click="turnOnDeleteModal(curation.uuid)"
          >
            삭제
          </BButton>
        </BRow>
      </AppCollapseItem>
    </AppCollapse>

  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'
import useComptCuration from '../../useComptCuration'
import useDeleteModal from '../../useDeleteModal'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyRangeInputV2,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const {
      getTitle,
    } = useComptCuration()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    return {
      state,
      getters,
      stateOptions,
      getTitle,
      turnOnDeleteModal,
    }
  },
})
</script>
